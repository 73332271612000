<script>
import { required, helpers, minLength, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Swal from "sweetalert2";

import animationData from "@/components/widgets/rhvddzym.json";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

const strongPass = helpers.regex(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/
);

export default {
  components: {
    Multiselect,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    data: {
      password: {
        required: helpers.withMessage(
          "Veuillez entrer un mots de passe",
          required
        ),
        minLength: helpers.withMessage("8 characteres minimum", minLength(8)),
        maxLength: helpers.withMessage("20 characteres maximum", maxLength(20)),
        strongPass: helpers.withMessage(
          "Au moins un chiffre , une majuscule et un charactere !@#$%^&*",
          strongPass
        ),
      },
      password_confirmation: {
        required: helpers.withMessage(
          "Veuillez confirmer votre mots de passe",
          required
        ),
      },
      gender: {
        required: helpers.withMessage(
          "Veuillez selectionnez un genre",
          required
        ),
      },
      nom: {
        required: helpers.withMessage("Veuillez entrer votre prenom", required),
        minLength: helpers.withMessage("2 characteres minimum", minLength(2)),
        maxLength: helpers.withMessage("20 characteres maximum", maxLength(20)),
      },
      prenom: {
        required: helpers.withMessage("Veuillez entrer votre nom", required),
        minLength: helpers.withMessage("2 characteres minimum", minLength(2)),
        maxLength: helpers.withMessage("20 characteres maximum", maxLength(20)),
      },
    },
  },
  data() {
    return {
      user: {
        password: null,
        password_confirmation: null,
      },
      allUser: {},
      userData: [],
      data: {},
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false,
      defaultOptions: { animationData: animationData },
    };
  },
  mounted() {
    this.v$.data.$touch();
    this.getUserWithSlug();
  },
  methods: {
    passwordToggle(id) {
      var value = "password";
      var type = document.getElementById(id).getAttribute("type");

      if (type === value) value = "text";

      document.getElementById(id).setAttribute("type", value);
    },
    reset() {
     this.data.slug = this.$route.params.slug;
      const self = this;
      this.$store
        .dispatch("postRequest", {
          route: "/api/AccountConfirm",
          data: this.data 
        })
        .then(
          function (response) {
            Swal.fire(
              response.data.message,
              "votre mot de passe a été mit à jour!",
              "success"
            );
            self.$router.push("/login");
          },
          function (error) {
            console.log(error);
            Swal.fire({
              title: "Oops...",
              text: "Il y a eu une erreur!",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
          }
        );
    },
    getUserWithSlug() {
      const self = this;
      this.$store
        .dispatch("getRequest", {
          route: `api/getUserWithSlug/${this.$route.params.slug}`,
          data: this.data,
        })
        .then(
          function (response) {
            self.data = response.data;
          },
          function (error) {
            console.log(error);
          }
        );
    },
    tryToReset() {
      self.submitted = true;
      this.v$.$touch();

      this.submited = true;
      if (
        this.v$.$error ||
        this.data.password_confirmation != this.data.password
      ) {
        console.log(this.data.password_confirmation == this.data.password);
        console.log("error");
        return false;
      }

      this.reset();
    },
  },
  computed: {},
};
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120"
        >
          <path
            d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"
          ></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/favicon.png" alt="" height="20" />
                  <span
                    style="
                      color: #fff;
                      font-size: 20px;
                      font-weight: 600;
                      position: relative;
                      top: 4px;
                    "
                  >
                    SLiM
                  </span>
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">CPL Saint Christophe</p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Account Confirmation</h5>
                  <!-- <p class="text-muted">VOTRE NOM : {{ userData?.name }}</p> -->
                </div>
                <div class="p-2">
                  <b-alert
                    v-model="isResetError"
                    class="mb-4"
                    variant="danger"
                    dismissible
                    >{{ error }}</b-alert
                  >
                  <form @submit.prevent="tryToReset">
                    <div class="mb-2">
                      <label for="nom" class="form-label"
                        >Nom<span class="text-danger">*</span></label
                      >
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input
                          type="text"
                          class="form-control pe-5"
                          v-model="data.nom"
                          :class="{
                            'is-invalid':
                              (v$.data.nom.$error && data.nom) ||
                              (v$.data.nom.$error && submited),
                          }"
                          placeholder="Nom"
                          name="nom"
                        />
                        <div
                          v-for="(item, index) in v$.data.nom.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.nom) ||
                              (v$.data.nom.$error && submited)
                            "
                            >{{ item.$message }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="mb-2">
                      <label for="prenom" class="form-label"
                        >Prénom<span class="text-danger">*</span></label
                      >
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input
                          type="text"
                          class="form-control pe-5"
                          v-model="data.prenom"
                          :class="{
                            'is-invalid':
                              (v$.data.prenom.$error && data.prenom) ||
                              (v$.data.prenom.$error && submited),
                          }"
                          placeholder="Prénom"
                          name="prenom"
                        />
                        <div
                          v-for="(item, index) in v$.data.prenom.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.prenom) ||
                              (v$.data.prenom.$error && submited)
                            "
                            >{{ item.$message }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="mb-2">
                      <label for="numero_de_telephone" class="form-label"
                        >Numéro de Téléphone </label
                      >
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input
                          type="text"
                          class="form-control pe-5"
                          readonly
                          v-model="data.numero_de_telephone"
                          placeholder="Numéro de Téléphone"
                          name="numero_de_telephone"
                        />
                      </div>
                    </div>

                    <div class="mb-2">
                      <label for="email" class="form-label"
                        >Email</label
                      >
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input
                          type="text"
                          class="form-control pe-5"
                          v-model="data.email"
                          placeholder="Adresse mail"
                          name="email"
                        />
                      </div>
                    </div>

                    <div class="mb-2">
                      <label for="genre" class="form-label"
                        >Genre<span class="text-danger">*</span></label
                      >
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <Multiselect
                          v-model="data.gender"
                          placeholder="Genre"
                          :class="{
                            'is-invalid':
                              (v$.data.gender.$error && data.gender) ||
                              (v$.data.gender.$error && submited),
                          }"
                          :close-on-select="true"
                          :searchable="false"
                          :show-labels="false"
                          :options="[
                            { value: 'masculin', label: 'Homme' },
                            { value: 'feminin', label: 'Femme' },
                          ]"
                        />
                        <div
                          v-for="(item, index) in v$.data.gender.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.gender) ||
                              (v$.data.gender.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="mb-2">
                      <label for="userpassword" class="form-label"
                        >Mots de passe <span class="text-danger">*</span></label
                      >
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input
                          type="password"
                          class="form-control pe-5"
                          v-model="data.password"
                          :class="{
                            'is-invalid':
                              (v$.data.password.$error && data.password) ||
                              (v$.data.password.$error && submited),
                          }"
                          id="password"
                          placeholder="Mots de passe"
                          name="password"
                        />
                        <button
                          @click="passwordToggle('password')"
                          class="btn btn-link position-absolute end-0 top-0"
                          type="button"
                        >
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                        <div
                          v-for="(item, index) in v$.data.password.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.password) ||
                              (v$.data.password.$error && submited)
                            "
                            >{{ item.$message }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="mb-2">
                      <label for="password_confirmation" class="form-label"
                        >Retapez le mots de passe
                        <span class="text-danger">*</span></label
                      >
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input
                          type="password"
                          class="form-control pe-5"
                          v-model="data.password_confirmation"
                          :class="{
                            'is-invalid':
                              (data.password_confirmation != data.password &&
                                data.password_confirmation) ||
                              (data.password_confirmation != data.password &&
                                submited),
                          }"
                          id="passwordConfirm"
                          placeholder="Mots de passe"
                          name="password_confirmation"
                        />
                        <button
                          @click="passwordToggle('passwordConfirm')"
                          class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button"
                        >
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                        <div
                          v-if="
                            (data.password_confirmation &&
                              data.password_confirmation != data.password) ||
                            (data.password_confirmation != data.password &&
                              submited)
                          "
                          class="invalid-feedback"
                        >
                          <span>Veuillez entrer les memes mots de passe !</span>
                        </div>
                      </div>
                    </div>

                    <div class="text-center mt-4">
                      <button class="btn btn-success w-100" type="submit">
                        Confirmer
                      </button>
                    </div>
                  </form>
                  <!-- end form -->
                </div>
              </div>
              <!-- end card body -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
